.button {
    width: 220px;
    height: 40px;
    margin-top: 5em;
    display: flex;
    background-color: #171C22 ;
    border: solid 1px hsl(213, 17%, 20%);
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 
        2px 0 10px 0 rgba(0, 0, 0, 0.12),
        2px 0 10px 0 rgba(0, 0, 0, 0.09);
    cursor: pointer;
}

p{ 
    color:#fff;
    font-size: 15px;
    padding-left: 5px;
}

.fa {
    color: white;
}


.button:hover {
    
    background-color: #40A89B;
    border: solid 1px #19564F;
    box-shadow: 
        2px 0 10px 0 rgba(140, 192, 189, 0.12),
        2px 0 10px 0 rgba(168, 188, 189, 0.09);
    transform: scale(1.1);
    transition: all 0.5s;
}
.button:hover fa{
    color: #19564F;
}

.button:active {
    background-color:#19564F;
    
}
