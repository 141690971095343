.navbar {
  background-color: #20272F;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  font-family: 'Oxygen', sans-serif;
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 20;
}

.nav-container {
  /*display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 980px;
  height: 80px;
  max-width: 1500px;
  */
  width: 90%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /*z-index: 10;*/
}

.nav-logo {
  color: #f5b921;
  align-items: center;
  margin-top: 2px;
  /*margin-left: 20px;*/
  cursor: pointer;
  text-decoration: none;
  /*font-size: 2rem;*/
  flex-grow: 1;
}

.nav-logo img {
  height: 25px;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.nav-links {
  color: #A6A6A6;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
  cursor: pointer;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
  
}

.nav-links:hover {
  color: #27A17E;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  margin-left: 17px ;
  width: 80%;
  background: #27A17E;
  color: #27A17E;
}

.nav-item .active {
  color: #27A17E;
  /*border: 1px solid #ffdd40;*/
}

.nav-icon {
  display: none;
}

@media screen and (max-width: 750px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #171C22;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color: #27A17E;
    border: none;
  }
  .nav-item:hover:after {
    margin-left: 0 ;
    width: 100%;
    background: #27A17E;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #27A17E;
  }
  .nav-logo img {
    justify-content: center;
    align-items: center;
  }
}
