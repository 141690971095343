
main.conteudo {
    min-height: 700px;
    margin: 0;
    background-color: #20272F;
    overflow-x: hidden;
}

aside.container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
    align-items: center;
    min-height: 80vh;
    width: 90%;
    margin: auto;
    

}
/*Textos e botões*/
aside.container h1 {
    
    color: #fff;
    text-align: justify;
    font-size: 1.6rem;
    font-weight: 350;
    white-space: nowrap;


}

aside.container span {
    color: #ffffff;
}


aside.container span:nth-child(2) {
    font-size: 2.9rem;
    color: #33D9A9;
}
aside.botaos {
    margin-top: 12px;
    display: flex;
    gap: 15px;

}







aside.container img {
    width: 400px;
    
    
}

@media screen and (max-width: 870px) {
    #root {
        width: 100%;
    }
    .conteudo .container{
        width: 50%;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 30px 0;
    }
    aside.container img {
        order: -1;
        margin: 0;
        margin-bottom: 35px;
        width: min(350px, 75vw);
        margin-bottom: 2rem;

    }
    aside.container h1 {
        font-size: 80%;
        text-align: center;
    }
    
    aside.botaos {
        display: flex;

        gap: 5;

        justify-content: center;
        align-items: center;
    }


    /*aside.botaos:nth-child(2) {
        margin-right: 100px;
    }*/
}

@media screen and (max-width: 560px) {
    aside.container h1 {
        
        text-align: center;
    }
    aside.botaos {
        padding-top: 15px;
        gap: 15;
    }
    
}


