aside.contCard {
    text-align: center;
    background-color: #20272F;
    width: 300px;
    border-radius: 14px;
    padding: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 6px 4px 4px -2px #14181d;
    color: #FFFFFF;
    box-sizing: border-box;

    

}

aside.contCard img {
    width: 100px;
    height: 99px;    
    /*width: 45%;
    height: 35%;
    width: 100px;
    height: 99px;*/
}

aside.textCard h1 {
    font-family: 'Oxygen';
    margin-bottom: 15px;
    Font-size: 1rem;
    text-align: none;
}

aside.textCard :nth-child(1) {
    font-size: 1.5rem;
    /* font-weight: 400; */
    margin: 0;
    margin-top: 23px;
}

aside.textCard :nth-child(2) {
    margin: 0;
    margin-top: 14px;
    margin-bottom: 15px;
    
}
aside.contCard:hover{
    background-color: #40A89B;
    border: solid 1px #19564F;
    box-shadow: 
        2px 0 10px 0 rgba(140, 192, 189, 0.12),
        2px 0 10px 0 rgba(168, 188, 189, 0.09);
    color: #0b2b22;
    transform: scale(1.1);
    transition: all 0.5s;
    
    
}

