
main.conteudoSobre {
    min-height: 100vh;
    margin: 0;
    background-color: #20272F;
    overflow-x: hidden;
}

aside.containerSobre {
    /*width: 90%;
    max-width: 980px; 
    margin: auto;
    padding: 100px 0;
    display: flex;*/
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    min-height: 934px;
}
/*Textos e botões*/
aside.containerSobre h1 {
    
    color: #fff;
    font-size: 1rem;
    font-weight:1px;
    
    
    

}

.textoSobre {
    
    margin-left: 50px;
}

.textoSobre .Titulo {
    color: #33D9A9;
    font-weight: 600;
    font-size: 2.5rem;
    margin-bottom: 10px;
}

aside.containerSobre span {
    color: #33D9A9;
}

aside.containerSobre span:nth-child(2) {
    font-size: 2.5rem;
    color: #33D9A9;
}
aside.botaosSobre {
    margin-top: 12px;
    display: flex;
    
    

}

aside.containerSobre img {
    width: 400px;
    padding: 20px;
    margin: auto;
}

@media screen and (max-width: 870px) {
    .conteudoSobre .containerSobre{
        margin: 0;
        flex-direction: column;
        text-align: justify;
        align-items: center;
        justify-content: center;
        padding: 30px 0;
    }
    aside.containerSobre img {
        order: -1;
        margin: 0;
        margin-bottom: 35px;
        width: min(350px, 75vw);
        margin-bottom: 2rem;

    }
    aside.containerSobre h1 {
        font-weight: 350;
        text-align: justify;
    }
    
    aside.botaosSobre {
        display: flex;

        gap: 5;

        justify-content: center;
        align-items: center;
    }
    .textoSobre .Titulo {
        text-align: center;
    }


    /*aside.botaos:nth-child(2) {
        margin-right: 100px;
    }*/
}

.sobre__mim{
    font-weight: 400;
}

@media screen and (max-width: 560px) {
    aside.containerSobre h1 {
        text-align:initial;
    }
    .textoSobre .Titulo {
        text-align: initial;
    }
    aside.botaosSobre {
        padding-top: 15px;
        gap: 15;
    }
    
}


